import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import ConstructionIcon from '@mui/icons-material/Construction';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import HomeIcon from '@mui/icons-material/Home';
import AssignmentIcon from '@mui/icons-material/Assignment';
import PeopleIcon from '@mui/icons-material/People';
import QuizIcon from '@mui/icons-material/Quiz';
import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';
import { getClasesByTrabajador, RootState, useAppDispatch, useAppSelector } from '../../store';
import { useAuthUser } from 'react-auth-kit';
import { Clase, TIPO_CLASE } from '../../interfaces/entities';
import { useAPIData } from '../../api/useAPIData';
import LockIcon from '@mui/icons-material/Lock';
interface PropsMenu {
    title: string
}

export function AdminListItems() {
    const navigate = useNavigate();
    const handleClick = (path: string) => {
        navigate(path, { replace: false })
    };

    return (
        <React.Fragment>
            <ListSubheader component="div" inset>
                Administraci&oacute;n
            </ListSubheader>
            <ListItemButton onClick={() => handleClick('/')}>
                <ListItemIcon>
                    <HomeIcon sx={{ color: 'secondary.main' }} />
                </ListItemIcon>
                <ListItemText primary="Inicio" />
            </ListItemButton>
            <ListItemButton onClick={() => handleClick('/admin/clientes')}>
                <ListItemIcon>
                    <PeopleIcon sx={{ color: 'secondary.main' }} />
                </ListItemIcon>
                <ListItemText primary="Clientes" />
            </ListItemButton>
            <ListItemButton onClick={() => handleClick('/admin/banco-preguntas')}>
                <ListItemIcon>
                    <QuizIcon sx={{ color: 'secondary.main' }} />
                </ListItemIcon>
                <ListItemText primary="Banco de preguntas" />
            </ListItemButton>
            <ListItemButton onClick={() => handleClick('/admin/informes')}>
                <ListItemIcon>
                    <QuizIcon sx={{ color: 'secondary.main' }} />
                </ListItemIcon>
                <ListItemText primary="Informes" />
            </ListItemButton>
        </React.Fragment>
    );
}

export function TrabajadorListItems(props: PropsMenu) {
    const { title } = props;
    const navigate = useNavigate();
    const handleClick = (path: string) => {
        navigate(path, { replace: false })
    };

    const auth = useAuthUser();

    const dispatch = useAppDispatch();
    const { clases: clasesReducer } = useAppSelector((state: RootState) => state.clases);
    // States for enable nav links
    const [induccionDisabled, setInduccionDisabled] = React.useState(true)
    const [capacitacionDisabled, setCapacitacionDisabled] = React.useState(true)
    const [documentacionDisabled, setDocumentacionDisabled] = React.useState(true)


    React.useEffect(() => {
        dispatch(getClasesByTrabajador(auth()?.id_trabajador || '0'));
    }, []);

    useAPIData(clasesReducer, React.useMemo(() => ({
        onFulfilled: (data: Clase[]) => {
            data.map(clase => {
                switch (String(clase.tipo)) {
                    case 'induccion':
                        setInduccionDisabled(false)
                        break;
                    case 'capacitacion':
                        setCapacitacionDisabled(false)
                        break;
                    case 'documentacion':
                        setDocumentacionDisabled(false)
                        break;
                    default:
                        break;
                }
            })
        },
        onRejected: error => {
            console.log(error);
        },
        onPending: () => {
        }
    }), [clasesReducer]));




    return (

        <React.Fragment>
            <ListSubheader component="div" inset>
                {title}
            </ListSubheader>
            <ListItemButton onClick={() => handleClick('/trabajador/default')}>
                <ListItemIcon>
                    <HomeIcon sx={{ color: 'secondary.main' }} />
                </ListItemIcon>
                <ListItemText primary="Inicio" />
            </ListItemButton>
            <ListItemButton onClick={() => handleClick('/trabajador/induccion')} disabled={induccionDisabled} sx={
                {
                    filter: induccionDisabled ? 'grayscale(1)' : 'none',
                    opacity: induccionDisabled ? 0.5 : 1
                }
            }>
                <ListItemIcon>
                    {
                        induccionDisabled ? <LockIcon /> : <MenuBookIcon sx={{ color: 'secondary.main' }} />
                    }
                </ListItemIcon>
                <ListItemText primary="Inducción" />
            </ListItemButton>
            <ListItemButton onClick={() => handleClick('trabajador/capacitacion')} disabled={capacitacionDisabled} sx={
                {
                    filter: capacitacionDisabled ? 'grayscale(1)' : 'none',
                    opacity: capacitacionDisabled ? 0.5 : 1
                }
            }>
                <ListItemIcon>
                    {
                        capacitacionDisabled ? <LockIcon /> : <ConstructionIcon sx={{ color: 'secondary.main' }} />
                    }
                </ListItemIcon>
                <ListItemText primary="Capacitación" />
            </ListItemButton>
            <ListItemButton alignItems='flex-start' onClick={() => handleClick('trabajador/documentacion')} disabled={documentacionDisabled} sx={
                {
                    filter: documentacionDisabled ? 'grayscale(1)' : 'none',
                    opacity: documentacionDisabled ? 0.5 : 1
                }
            }>
                <ListItemIcon>
                    {
                        documentacionDisabled ? <LockIcon /> : <PictureAsPdfIcon sx={{ color: 'secondary.main' }} />
                    }
                </ListItemIcon>
                <ListItemText disableTypography>
                    <Typography sx={{ display: 'inline' }} component="span" variant="body1" color="text.primary">
                        Sistema de gestión<br />SST
                    </Typography>
                </ListItemText>
            </ListItemButton>
        </React.Fragment>
    );
}

export function EmpresaListItems() {
    const navigate = useNavigate();
    const handleClick = (path: string) => {
        navigate(path, { replace: false })
    };

    return (
        <React.Fragment>
            <ListSubheader component="div" inset>
                Empresa
            </ListSubheader>
            <ListItemButton>
                <ListItemIcon>
                    <AssignmentIcon sx={{ color: 'secondary.main' }} />
                </ListItemIcon>
                <ListItemText primary="Inicio" />
            </ListItemButton>
        </React.Fragment>
    );
}